import {memoize} from 'lodash';
import tinycolor from 'tinycolor2';

const getBrandStyle = (style: Partial<BrandStyle> = {}): BrandStyle => {
  const primaryColor = style?.primaryColor || '#3f4144';
  const hslColor = tinycolor(primaryColor).toHsl();

  return {
    primaryColor,
    generatedLightColor: tinycolor({...hslColor, l: 0.96}).toHexString(),
    darkColor: tinycolor({...hslColor, l: 0.15}).toHexString(),
    shadowColor: tinycolor({...hslColor, l: 0.2})
      .setAlpha(0.3)
      .toHex8String(),
    ...style
  };
};

export const getBrandStyleCSS = (style: Partial<BrandStyle> = {}) => {
  const brandStyle = getBrandStyle(style);

  return {
    '--brand-primary-color': brandStyle.primaryColor,
    '--brand-dark-color': brandStyle.darkColor,
    '--brand-light-color': brandStyle.generatedLightColor
  } as React.CSSProperties;
};

export default memoize(getBrandStyle, (style = {}) => style.primaryColor);

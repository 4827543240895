import React from 'react';
import {twMerge} from 'tailwind-merge';

type Props = {
  style?: React.CSSProperties;
  className?: string;
};

const AnimatedLoader = ({style, className}: Props) => {
  return (
    <div
      style={style}
      className={twMerge(
        'bg-white w-[2px] h-4 shrink-0 absolute animate-[loadingCaret_2.2s_ease-in-out_-2.2s_reverse_infinite_both]',
        className
      )}
    />
  );
};

export default AnimatedLoader;

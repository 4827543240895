// import {useEffect, useState} from 'react';
import {destroyCookie, setCookie, parseCookies} from 'nookies';

export const tokenKey = 'panelista_token';
export const userIdKey = 'panelista_user_id';

export const saveToken = (token: string) => {
  setCookie(null, tokenKey, token, {
    path: '/',
    maxAge: 365 * 24 * 60 * 60,
    secure: process.env.NEXT_PUBLIC_IS_LOCAL !== 'true'
  });
};

const getCookie = (key: string) => {
  const cookies = parseCookies();

  return cookies[key];
};

export const saveUserId = (userId: string) => {
  setCookie(null, userIdKey, userId, {
    path: '/',
    maxAge: 365 * 24 * 60 * 60,
    secure: process.env.NEXT_PUBLIC_IS_LOCAL !== 'true'
  });
};

export const clearData = () => {
  destroyCookie(null, tokenKey, {path: '/'});
  destroyCookie(null, userIdKey, {path: '/'});
};

type FeatureFlag = 'autoContent';

export const setFeatureSetting = (featureName: FeatureFlag, value: boolean) => {
  if (!value) return destroyCookie(null, `FEATURE_${featureName}`, {path: '/'});

  setCookie(null, `FEATURE_${featureName}`, 'true', {
    path: '/',
    maxAge: 365 * 24 * 60 * 60,
    secure: process.env.NEXT_PUBLIC_IS_LOCAL !== 'true'
  });
};

// export const useFeatureSetting = (featureName: FeatureFlag) => {
//   const [value, setValue] = useState(false);

//   useEffect(() => {
//     setValue(getFeatureSetting(featureName));
//   }, [featureName]);

//   return value;
// };

export const getFeatureSetting = (featureName: string) => {
  return Boolean(getCookie(`FEATURE_${featureName}`));
};

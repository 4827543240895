import {atom, useAtomValue} from 'jotai';
import {useQuery} from '@tanstack/react-query';
import {clientRequest} from 'src/client/apiClient';

type UserAtom = User | null;

export const userAtom = atom<UserAtom>(null);

export const useUser = (initialUser?: User) => {
  const user = useAtomValue(userAtom);

  const {data, isLoading} = useQuery<User>({
    queryKey: ['me'],
    queryFn: () => clientRequest({url: '/users/me'}),
    initialData: initialUser
  });

  return {
    user: (data || user) as User | null,
    isLoadingUser: isLoading
  };
};

/*
  App constants
*/

export const answerCharacterLimit = 2500;

/*
  UI constants
*/

export const brandRadius = 6;

export const discussionAdminMobileThreshold = 900;

export const mediumGrayText = '#848484';
export const errorColor = '#E00026';
export const highlightColor = '#FFF4B8';
export const lightBackgroundColor = '#F7F8F9';

export const purpleBankColor = '#704ADD';
export const greenTransitColor = '#32b67a';
export const redStartupColor = '#E54B4B';

export const panelistaDarkColor = '#0E494D';
export const panelistaPrimaryColor = '#1F9DA3';
export const panelistaLightColor = '#E9F7F8';

export const optionColors = ['#FFCF55', '#C6CCD4', '#E9B687'];

export const sizeDelimiter = '_|_';

export const websiteBucket = 'https://panelista.s3.nl-ams.scw.cloud/website';

export const uppyService = 'https://api2-eu-west-1.transloadit.com';

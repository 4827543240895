import {get} from 'lodash';
import {useEffect} from 'react';
import {FormikProps} from 'formik';

const useScrollErrorIntoView = (inputRef: any, id: string, formikProps: Partial<FormikProps<any>>) => {
  useEffect(() => {
    if (!formikProps.submitCount) return;

    const error = get(formikProps.errors, id);

    if (error && inputRef.current) {
      inputRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [formikProps.submitCount]);
};

export default useScrollErrorIntoView;

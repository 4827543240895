import {twMerge} from 'tailwind-merge';

type Props = {
  style?: React.CSSProperties;
  color?: string;
  icon?: any;
  name?: string;
  size?: number;
  className?: string;
};

const RemixIcon = ({style, icon, name, color = 'var(--gray-text)', size, className}: Props) => {
  const IconComponent = icon;

  const sizeStyle = size ? {width: size, height: size} : null;

  return (
    <div
      style={{fill: color, ...sizeStyle, ...style}}
      className={twMerge('w-[24px] h-[24px] h-icon shrink-0 leading-none [&>svg]:fill-inherit', className)}
    >
      {icon ? (
        <IconComponent />
      ) : (
        <svg viewBox="0 0 24 24" className="remix">
          <use xlinkHref={`/icons/remixicon.symbol.svg#ri-${name}`}></use>
        </svg>
      )}
    </div>
  );
};

export default RemixIcon;

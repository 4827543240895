export const getCountryCode = (language: string) => {
  switch (language) {
    case 'en':
      return 'US';
    case 'sv':
      return 'SE';
    case 'no':
      return 'NO';

    default:
      return 'SE';
  }
};

export const getLanguageFromCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'SE':
      return 'sv';

    case 'NO':
      return 'no';

    default:
      return 'en';
  }
};
